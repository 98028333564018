<template>
  <!-- PERFIL -->
  <v-app class="backcolor">
    <v-col cols="12" md="10" class="mx-auto">
      <v-card elevation="5" class="rounded-xl">
        <v-img
          :height="$vuetify.breakpoint.smAndUp ? '150px' : '80px'"
          :src="profileInfo.cover"
          @dblclick="seeCover = true"
        >
        </v-img>

        <v-row no-gutters class="pa-2" justify="center" align-content="center">
          <v-col cols="5" sm="3" lg="2" order="1" order-sm="1">
            <v-sheet
              class="mt-n16"
              :class="$vuetify.breakpoint.xsOnly ? 'ml-2' : 'ml-3'"
              style="background: content-box;"
            >
              <v-img
                class="d-flex align-center text-center rounded-circle"
                :src="profileInfo.avatar"
                style="border: 3px solid white"
                aspect-ratio="1"
                @dblclick="seePicture = true"
              >
                <!-- <v-btn
                  v-if="$vuetify.breakpoint.xsOnly"
                  fab
                  class="opacity-100"
                  @click="editPicture()"
                >
                </v-btn> -->
              </v-img>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="7" lg="8" order="3" order-sm="2">
            <div
              :style="
                $vuetify.breakpoint.xsOnly
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1.4rem'
              "
              class="font-weight-bold pl-3"
            >
              {{ profileInfo.student.name }}
            </div>
            <div
              class="pl-3 font-weight-medium"
              :style="$vuetify.breakpoint.smAndUp ? '' : 'font-size: 1rem'"
            >
              {{ profileInfo.student.team.name }}

              <span class="px-2" v-if="$vuetify.breakpoint.xsOnly">|</span>
              <br v-if="$vuetify.breakpoint.smAndUp" />
              {{ profileInfo.student.school.nickname }}
            </div>
          </v-col>
          <v-col cols="7" sm="2" order="2" order-sm="3">
            <v-btn
              fab
              absolute
              right
              small
              dark
              color="darkpink"
              class="mt-2"
              @click="editProfile()"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="my-2">
        <v-col cols="12" lg="7" style="height: 86vh">
          <v-card elevation="5" class="rounded-xl pa-6" style="height: 100%">
            <v-row no-gutters>
              <v-col cols="12" md="8">
                <v-card-title
                  class="text-blue font-weight-black text-h6 text-sm-h5"
                >
                  <v-avatar
                    v-if="!$vuetify.breakpoint.lgAndUp"
                    size="55"
                    class="mr-2"
                  >
                    <img src="../../assets/Maker_Trophy.svg" />
                  </v-avatar>
                  <span class="text-blue">{{ $t('student.student_minhainformacao.informacao1') }}</span>
                  <!-- <v-icon right color="black">mdi-trophy-variant</v-icon> -->
                </v-card-title>
                <v-card-text style="font-size: 1.2rem">
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_minhainformacao.informacao2') }}
                    <span class="font-weight-regular">
                      {{ profileInfo.student.name }}
                    </span>
                  </div>
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_minhainformacao.informacao3') }}
                    <span class="font-weight-regular">
                      {{ profileInfo.student.user }}
                    </span>
                  </div>
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_minhainformacao.informacao4') }}
                    <span class="font-weight-regular">
                      {{ profileInfo.student.birthday }}
                    </span>
                  </div>
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_minhainformacao.informacao5') }}
                    <span class="font-weight-regular">
                      {{ profileInfo.student.phone }}
                    </span>
                  </div>
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_label.label7') }}
                    <span class="font-weight-regular">
                      {{ profileInfo.student.email }}
                    </span>
                  </div>
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_minhainformacao.informacao7') }}
                    <!-- <span class="font-weight-regular"> {{ profileInfo }} </span> -->
                  </div>
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_minhainformacao.informacao8') }}
                    <span class="font-weight-regular">
                      {{ profileInfo.student.school.name }}
                    </span>
                  </div>
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_minhainformacao.informacao9') }}
                    <span class="font-weight-regular">
                      {{ profileInfo.student.school.city }} -
                      {{ profileInfo.student.school.state }}
                    </span>
                  </div>
                  <div class="font-weight-bold my-2">
                    {{ $t('student.student_label.label4') }}
                    <span class="font-weight-regular">
                      {{ profileInfo.student.team.name }}
                    </span>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="7" v-if="sas">
          <v-card elevation="5" class="rounded-xl pa-6">
            <v-row no-gutters>
              <v-col cols="12" md="8">
                <v-card-title
                  class="text-blue font-weight-black text-h6 text-sm-h5"
                >
                  <v-avatar
                    v-if="!$vuetify.breakpoint.lgAndUp"
                    size="55"
                    class="mr-2"
                  >
                    <img src="../../assets/Maker_Trophy.svg" />
                  </v-avatar>
                  <span class="text-blue">{{ $t('student.student_conquista.minhaconquista') }}</span>
                  <!-- <v-icon right color="black">mdi-trophy-variant</v-icon> -->
                </v-card-title>

                <v-card
                  elevation="3"
                  class="my-3 rounded-lg"
                  :color="classColors[2].back"
                >
                  <v-list-item>
                    <v-list-item-avatar
                      class="my-2"
                      size="80"
                      style="border: 3px solid #f9c228"
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      <v-img :src="profileInfo.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="font-weight-bold" style="font-size: 1rem">
                        {{ $t('student.student_parabens') }}
                      </div>

                      <div style="font-size: 0.9rem">
                        {{ $t('student.stundent_aula.aula_maxnote') }}
                        <strong>{{ $t('student.student_projeto.projeto5') }}</strong>!
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card
                  elevation="5"
                  class="my-3 rounded-lg"
                  :color="classColors[1].back"
                >
                  <v-list-item>
                    <v-list-item-avatar
                      class="my-2"
                      size="80"
                      style="border: 3px solid #1cfee8"
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      <v-img :src="profileInfo.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="font-weight-bold" style="font-size: 1rem">
                        {{ $t('student.student_recorde.recorde1') }}
                      </div>
                      <div style="font-size: 0.9rem">
                        {{ $t('student.student_recorde.recorde2') }}
                        <strong>{{ $t('student.student_recorde.recorde3') }}</strong>!
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>

              <!-- PROGRESSO DE AULAS ASSISTIDAS -->
              <v-col
                md="4"
                align-self="center"
                class="mx-auto"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <div class="d-flex justify-center pl-4">
                  <v-img src="../../assets/King.svg" max-width="150px"></v-img>
                </div>
              </v-col>

              <v-col cols="12" md="8">
                <v-img
                  height="250"
                  src="http://www.makereducacional.com.br/astronaut/gameevent/Games And Events-06.svg"
                  class="rounded-lg"
                >
                  <span
                    class="
                      float-left
                      white
                      blue--text
                      font-weight-bold
                      py-1
                      px-4
                      my-3
                      mx-4
                      rounded-pill
                    "
                    style="font-size: 0.8rem"
                  >
                    {{ $t('student.student_recorde.recorde4') }}
                  </span>
                </v-img>
              </v-col>

              <v-col
                cols="12"
                md="4"
                :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'pl-4'"
              >
                <v-card
                  class="
                    white--text
                    rounded-lg
                    pa-2
                    d-flex
                    flex-column
                    justify-space-around
                    text-center
                    align-center
                  "
                  color="blue"
                  height="250px"
                >
                  <span
                    class="
                      float-left
                      white
                      blue--text
                      font-weight-bold
                      py-1
                      px-4
                      rounded-pill
                    "
                    style="font-size: 0.8rem"
                  >
                    {{ $t('student.student_projeto.projeto1') }}
                  </span>

                  <div style="font-size: 0.8rem">
                    {{ $t('homework.homework_detalhes.detalhes1') }}
                    <div style="font-size: 1.1rem" class="font-weight-medium">
                      {{ $t('student.student_projeto.projeto2') }}
                    </div>
                  </div>

                  <div style="font-size: 0.8rem">
                    {{ $t('student.student_projeto.projeto3') }}
                    <div style="font-size: 1.1rem" class="font-weight-medium">
                      8.7
                    </div>
                  </div>

                  <span
                    class="
                      px-2
                      py-1
                      darkblue--text
                      white
                      rounded-lg
                      font-weight-medium
                    "
                    style="font-size: 0.9rem"
                  >
                    <v-icon small color="green"> mdi-emoticon-happy </v-icon>
                    {{ $t('student.student_projeto.projeto4') }}
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5" style="height: 86vh">
          <v-card
            elevation="5"
            class="rounded-xl pa-4 fill-height"
            style="height: 100%"
          >
            <v-card-title class="font-weight-black text-h6 text-sm-h5">
              <v-avatar
                v-if="!$vuetify.breakpoint.lgAndUp"
                size="55"
                class="mr-1"
              >
                <img src="../../assets/Maker_Medal.svg" />
              </v-avatar>
              <span class="text-blue">{{ $t('student.student_turmaatividade') }}</span>
              <!-- <v-icon right color="black">mdi-account-group</v-icon> -->
              <v-spacer></v-spacer>
              <v-tooltip v-model="show" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    dark
                    color="darkpink"
                    v-bind="attrs"
                    v-on="on"
                    @click="friendsList()"
                  >
                    <v-icon> mdi-account-group </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('botoes.botao_ver') }}</span>
              </v-tooltip>
            </v-card-title>
            <v-col cols="12" class="mx-auto">
              <!-- CARDS DE ATIVIDADES DA SALA -->
              <v-card
                v-for="(v, index) in friendsAchiv"
                :key="v.id"
                elevation="3"
                class="rounded-lg mb-4"
                :color="classColors[index].back"
              >
                <v-list-item>
                  <v-list-item-avatar
                    class="my-2 pointer"
                    size="80"
                    :style="'border: 3px solid' + classColors[index].light"
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <v-img :src="v.avatar" @click="toFriendProfile(v)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div class="font-weight-bold" style="font-size: 1rem">
                      {{ $t('student.student_parabens') }}
                    </div>

                    <div style="font-size: 0.9rem">
                      <strong class="pointer" @click="toFriendProfile(v)">{{
                        v.name
                      }}</strong>
                      {{ $t('student.stundent_aula.aula_max') }}
                      <strong>{{ $t('student.student_projeto.projeto5') }}</strong>!
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="friendsDialog"
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : '90%'"
      :fullscreen="$vuetify.breakpoint.xsOnyl"
    >
      <template>
        <v-card>
          <v-card-title class="darkblue white--text">{{ $t('student.student_minhaturma') }}</v-card-title>
          <v-card-text class="pa-4" v-if="friendsTeam.length !== 0">
            <v-card
              v-for="(f, index) in friendsTeam"
              :key="f.id"
              elevation="3"
              class="rounded-lg mb-4"
              :color="classColors[index].back"
              @click="toFriendProfile(f)"
            >
              <v-list-item>
                <v-list-item-avatar
                  class="my-2"
                  size="80"
                  :style="'border: 3px solid' + classColors[index].light"
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  <v-img :src="f.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="font-weight-bold" style="font-size: 1rem">
                    {{ f.name }}
                  </div>

                  <!-- <div style="font-size: 0.9rem">
                    {{ f.city }} - {{ f.state }}
                  </div> -->
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-card-text>
          <v-card-text v-if="friendsTeam.length === 0" class="pa-4 pb-8">
            <v-img
              src="../../assets/RocketTravel.svg"
              max-width="300px"
              class="mx-auto"
            />
            <div
              class="text-center text-h6 text-sm-h5 font-weight-bold mt-2"
              style="color: #491670"
            >
              {{ $t('student.student_amigos') }}
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      v-model="seePicture"
      class="rounded-xl"
      :max-width="$vuetify.breakpoint.smAndUp ? '30%' : '90%'"
    >
      <v-img :src="profileInfo.avatar" class="rounded-lg" />
    </v-dialog>
    <v-dialog
      v-model="seeCover"
      class="rounded-xl"
      :max-width="$vuetify.breakpoint.smAndUp ? '30%' : '90%'"
    >
      <v-img
        :src="profileInfo.cover"
        class="rounded-lg"
        position="right center"
        :aspect-ratio="1"
      />
    </v-dialog>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      coverIndex: null,
      classColors: [
        { light: "#FD80CD", dark: "#E245A6", back: "rgba(253, 128, 205, 0.3)" },
        { light: "#1CFEE8", dark: "#089790", back: "rgba(28, 254, 232, 0.3)" },
        { light: "#F9C228", dark: "#CF9C0E", back: "rgba(249, 194, 40, 0.3)" },
        { light: "#FD80CD", dark: "#E245A6", back: "rgba(253, 128, 205, 0.3)" },
      ],
      friendsAchiv: [
        {
          id: 1,
          name: this.$t('student.student_nome.nome1'),
          avatar:
            "http://www.makereducacional.com.br/astronaut/avatars/Ninja.svg",
        },
        {
          id: 2,
          name: this.$t('student.student_nome.nome2'),
          avatar:
            "http://www.makereducacional.com.br/astronaut/avatars/Duck.svg",
        },
        {
          id: 3,
          name: this.$t('student.student_nome.nome3'),
          avatar:
            "http://www.makereducacional.com.br/astronaut/avatars/Astronaut.svg",
        },
        {
          id: 4,
          name: this.$t('student.student_nome.nome4'),
          avatar:
            "http://www.makereducacional.com.br/astronaut/avatars/Aliencorn.svg",
        },
      ],
      friendsTeam: [],
      profileInfo: null,
      friendsDialog: false,
      show: false,
      seePicture: false,
      seeCover: false,
    };
  },
  async created() {
    await this.getProfile()
    this.confirmOption();
  },
  methods: {
    async getProfile() {
      let profile = await axios.get(`${url}/profilesForStudent`, {
        headers: {
          authorization: token,
        },
      });
      this.profileInfo = profile.data;
    },
    async setAvatar(res) {
      let alt = res.image;
      await axios.put(
        `${url}/profilesForStudent`,
        { avatar: alt },
        {
          headers: {
            authorization: token,
          },
        }
      );
      this.profileAvatar = false;
      this.getProfile();
    },
    async confirmCover(res) {
      let alt = res.src;
      await axios.put(
        `${url}/profilesForStudent`,
        { cover: alt },
        {
          headers: {
            authorization: token,
          },
        }
      );
      this.profileCover = false;
      this.getProfile();
    },
    confirmOption() {
      switch (this.onboarding) {
        case 0:
          this.picOptions = this.editAvatar[0];
          break;
        case 1:
          this.picOptions = this.editAvatar[1];
          break;
        case 2:
          this.picOptions = this.editAvatar[2];
          break;
        case 3:
          this.picOptions = this.editAvatar[3];
          break;
        case 4:
          this.picOptions = this.editAvatar[4];
          break;
      }
    },
    toFriendProfile(data) {
      this.$parent.$parent.$parent.friend = data;
      localStorage.getItem("friend")
      this.$emit("message", this.$t('admin.admin_menu.menu21'));
      this.classDialog = false;
      this.alert = false;
      this.$router.push("/student/Perfil/Friends")
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu21');
    },

    async friendsList() {
      this.friendsDialog = true;
      let id = this.profileInfo.student.teamId;

      let res = await axios.get(`${url}/profilesTeams?team=${id}`, {
        headers: {
          authorization: token,
        },
      });
      this.friendsTeam = res.data;
    },
    editProfile() {
      this.$router.push("/student/Perfil/Edit")
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu23');
    },
  },
};
</script>

<style lang="css" scoped>
.selected {
  border: 4px solid #468c00;
}

.unselected {
  transform: none;
  transition: all 0.5s;
}

.cursor {
  cursor: pointer;
}

.opacity-100 {
  opacity: 100%;
}

.text-pink {
  color: #fd80cd;
  -webkit-text-stroke: 1px #e245a6;
}

.text-yellow {
  color: #f9c228;
  -webkit-text-stroke: 1px #cf9c0e;
}

.text-blue {
  color: #1670e8;
}
</style>
